// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* You can add global styles to this file, and also import other style files */
.swiper-mobile > .swiper-wrapper {
  width: fit-content;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/styles.scss"],"names":[],"mappings":"AAAA,8EAAA;AACA;EACI,kBAAA;EACA,cAAA;AACJ","sourcesContent":["/* You can add global styles to this file, and also import other style files */\n.swiper-mobile > .swiper-wrapper {\n    width: fit-content;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
